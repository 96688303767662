import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import HomePage from './HomePage';
import HowItWorks from './HowItWorks';
import OurStory from './OurStory';
import Newsletter from './Newsletter';

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/howitworks" element={<HowItWorks />} />
                <Route path="/ourstory" element={<OurStory />} />
                <Route path="/newsletter" element={<Newsletter />} />
            </Routes>
        </Router>
    );
}


export default App;
