import React, { useState, useEffect } from 'react';
import { Box, Button, Center, Container, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import './HomePage.css'
function HomePage() {
    const textColor = useColorModeValue('gray.800', 'gray.50');

    const phrases = ["Read Word by Word.", "Focus Better.", "Improve Comprehension.", "Speed Up Your Reading."];
    const [phraseIndex, setPhraseIndex] = useState(0);
    const [visibleWordCount, setVisibleWordCount] = useState(0);

    useEffect(() => {
        const currentPhraseWords = phrases[phraseIndex].split(" ");
        if (visibleWordCount < currentPhraseWords.length) {
            const wordInterval = setInterval(() => {
                setVisibleWordCount(count => count + 1);
            }, 200);
            return () => clearInterval(wordInterval);
        } else {
            const timeout = setTimeout(() => {
                setVisibleWordCount(0);
                setPhraseIndex(index => (index + 1) % phrases.length);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [phraseIndex, visibleWordCount, phrases]);

    return (
        <Box minHeight="50vh">
            <Container maxW="container.xl" centerContent>
                <Heading as="h1" size={{ base: '3xl', md: '4xl' }} my={10} textAlign="center" color={textColor} mb={120}> {/* Increased bottom margin */}
                    Read Faster. Stay Focused.
                </Heading>
                <Center my={6} mb={100}> {/* Increased bottom margin */}
                    <Text fontSize={{ base: '3xl', md: '5xl' }} fontWeight="bold" color={textColor}>
                        {phrases[phraseIndex].split(" ").map((word, index) => (
                            <span key={index} className={index < visibleWordCount ? 'word visible' : 'word'}>
            {word}{' '}
        </span>
                        ))}
                    </Text>

                </Center>
                <a href="https://chromewebstore.google.com/detail/page-pacer/oflnmeohkhahdmnmbemakhbgkfefbfnn">
                    <Button
                        size="lg"
                        bg="#63B3ED"
                        color="white"
                        mt={5}
                        _hover={{
                            bg: '#74b3e0',
                            transform: 'scale(1.1)'
                        }}
                        _focus={{
                            boxShadow: '0 0 0 3px rgba(144, 205, 244, 0.9)',
                        }}
                        _active={{
                            bg: '#5faedc',
                        }}
                    >
                        Get Page Pacer
                    </Button>
                </a>
            </Container>
        </Box>

    );
}

export default HomePage;
