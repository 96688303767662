import React, { useState } from 'react';
import {
    Box,
    Flex,
    Button,
    useColorModeValue,
    Container,
    Heading,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    VStack,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);
    const location = useLocation();

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
            <Container maxW="container.xl">
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <Heading as="h1" size="lg" letterSpacing={'tighter'} as={RouterLink} to="/">
                        Page Pacer
                    </Heading>
                    <Flex alignItems={'center'}>
                        <IconButton
                            display={{ sm: 'flex', md: 'none' }}
                            icon={<HamburgerIcon />}
                            onClick={toggleMenu}
                            variant={'ghost'}
                        />
                        <Flex display={{ base: 'none', md: 'flex' }}>
                            {location.pathname !== '/' && (
                                <a href="https://chromewebstore.google.com/detail/page-pacer/oflnmeohkhahdmnmbemakhbgkfefbfnn">
                                    <Button
                                        bg="#63B3ED"
                                        color="white"
                                        _hover={{
                                            bg: '#74b3e0',
                                            transform: 'scale(1.1)'
                                        }}
                                        _focus={{
                                            boxShadow: '0 0 0 3px rgba(144, 205, 244, 0.9)',
                                        }}
                                        _active={{
                                            bg: '#5faedc',
                                        }}
                                    >
                                        Get Page Pacer
                                    </Button>
                                </a>
                            )}
                            <Button as={RouterLink} to="/howitworks" variant={'ghost'} mr={4}
                                    _hover={{ transform: 'scale(1.1)' }}>
                                How It Works
                            </Button>
                            <Button as={RouterLink} to="/ourstory" variant={'ghost'} mr={4}
                                    _hover={{ transform: 'scale(1.1)' }}>
                                Our Story
                            </Button>
                            <Button as={RouterLink} to="/newsletter" variant={'ghost'}
                                    _hover={{ transform: 'scale(1.1)' }}>
                                Newsletter
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>

            <Drawer isOpen={isOpen} placement="right" onClose={toggleMenu}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody>
                        <VStack mt={10} spacing={4} align="stretch">
                            {location.pathname !== '/' && (
                                <Button as={RouterLink} to="https://chromewebstore.google.com/detail/page-pacer/oflnmeohkhahdmnmbemakhbgkfefbfnn" variant={'ghost'} mr={4}
                                        _hover={{ transform: 'scale(1.1)' }}>
                                    Get Page Pacer
                                </Button>
                            )}
                            <Button as={RouterLink} to="/howitworks" variant={'ghost'}
                                    onClick={toggleMenu}>
                                How It Works
                            </Button>
                            <Button as={RouterLink} to="/ourstory" variant={'ghost'}
                                    onClick={toggleMenu}>
                                Our Story
                            </Button>
                            <Button as={RouterLink} to="/newsletter" variant={'ghost'}
                                    onClick={toggleMenu}>
                                Newsletter
                            </Button>
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default Navbar;
