import React from 'react';
import { Box, Container } from '@chakra-ui/react';

const Newsletter = () => {
    return (
        <Container maxW="100%" centerContent padding="0">
            <Box my={10} width="100%">
                <iframe
                    src="https://embeds.beehiiv.com/79ee413b-25bf-42ca-a488-7a2c9827fb20"
                    data-test-id="beehiiv-embed"
                    width="100%"
                    height="500" // Adjust height as needed
                    style={{ borderRadius: '4px', border: '2px solid #e5e7eb', backgroundColor: 'transparent' }}
                ></iframe>
            </Box>
        </Container>
    );
};

export default Newsletter;
