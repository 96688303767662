import React, { useState, useEffect } from 'react';
import { Box, Container, Heading, Text, Divider } from '@chakra-ui/react';

const OurStory = () => {
    const content = "Page Pacer was created to help you get stuff done. We were asked to solve a problem in Education. 2 in 3 American children can't read at a proficient level. Post-pandemic & virtual learning has made reading even more difficult. We made Page Pacer with one goal in mind: help people read.";
    const words = content.split(' ');
    const [visibleWordsCount, setVisibleWordsCount] = useState(0);

    useEffect(() => {
        if (visibleWordsCount < words.length) {
            const timer = setTimeout(() => {
                setVisibleWordsCount(visibleWordsCount + 1);
            }, 200); // Adjust time as needed for each word appearance
            return () => clearTimeout(timer);
        }
    }, [visibleWordsCount, words]);

    return (
        <Container maxW="container.xl" centerContent>
            <Box my={10}>
                {/* Increased heading sizes */}
                <Heading as="h2" size="4xl" mb={5}>Our Story</Heading>
                <Divider my={5}/>
                <Heading as="h3" size="2xl" mb={3}>Born from a WashU Entrepreneurship class,</Heading>
                {/* Increased text size */}
                <Text fontSize="2xl"> {/* Or you can use a specific value like '20px' */}
                    {words.map((word, index) => (
                        <span key={index} className={`word ${index < visibleWordsCount ? 'visible' : ''}`}>
                            {word + ' '}
                        </span>
                    ))}
                </Text>
            </Box>
        </Container>
    );
};

export default OurStory;
