import React, {useState, useEffect} from 'react';
import {Box, Container, Heading, Text, Divider} from '@chakra-ui/react';

const HowItWorks = () => {
    const content = "Traditional reading involves numerous eye movements that often contribute to slower reading speeds and increased strain. Page Pacer reduces unnecessary eye movements which leads to increased reading speed & comprehension. The Page Pacer allows you to read faster & stay focused with our clean animation, which is exactly how you're reading this right now. Wanna slow down? Wanna read faster? Page Pacer allows you to choose any speed you want and paces you throughout the reading.";


    const words = content.split(' ');
    const [visibleWordsCount, setVisibleWordsCount] = useState(0);

    useEffect(() => {
        if (visibleWordsCount < words.length) {
            const timer = setTimeout(() => {
                setVisibleWordsCount(visibleWordsCount + 1);
            }, 200); // Adjust time as needed for each word appearance
            return () => clearTimeout(timer);
        }
    }, [visibleWordsCount, words]);

    return (
        <Container maxW="container.xl" centerContent>
            <Box my={10}>
                {/* Increased heading sizes */}
                <Heading as="h2" size="4xl" mb={5}>How It Works</Heading>
                <Divider my={5}/>
                <Heading as="h3" size="2xl" mb={3}>Reducing Unnecessary Eye Movements</Heading>
                {/* Increased text size */}
                <Text fontSize="2xl"> {/* Or you can use a specific value like '20px' */}
                    {words.map((word, index) => (
                        <span key={index} className={`word ${index < visibleWordsCount ? 'visible' : ''}`}>
                            {word + ' '}
                        </span>
                    ))}
                </Text>
            </Box>
        </Container>
    );
};

export default HowItWorks;
